.test {
    background-color: dodgerblue;
    color:white;
    font-weight: bold;
    text-align: center;
    height: 30vh;
    margin-top: 100px;
    border-radius: 30px;
    border-width: 2px;
    border-color: black;
}


.login_container {
    background-color:white;
    margin:auto;
}

.quest_details_card {
    margin: 50px;
    border-radius: 20px;
    height: 300px;
    width: 80wv;    
    border-width: 1;
    border-color: grey;
    border-style: solid;
}

.logoutBox {
    width: 350px;
    height: 250px;
    border-radius: 10px;
    justify-content: "center";
    border-width: 1;
    border-color: black;
    border-style: solid;
}

.logoutContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}